import { AbstractControl } from '@angular/forms';

export function EmailValidator(control: AbstractControl) {
    const value = control.getRawValue();
    if (!value) {
        return null;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
        return { invalidEmail: true };
    }

    return null;
}
